import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import trackEvent from '../components/analytics';
import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
  ViewModuleName,
  ViewChoice,
  ViewImgTop,
  Breadcrumbs,
} from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/fr/voixoff_FR_9.mp3';
import Background from '../images/landscape-2.jpg';
import Borne1 from '../images/Bornes-1.png';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  overflow-y: auto;
  max-width: 100%;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
`;

const ViewIcons = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const ViewSmiley = styled.a`
  display: flex;
  border: 3px solid #ddd;
  background: #eee;
  padding: 0.5rem;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 0.2rem;
  animation: bounceIn;
  animation-duration: 1s;

  &:hover,
  &:active {
    transform: scale(1.2);
  }

  img {
    max-width: 50px;
  }
`;

const ViewH1 = styled.h1`
  font-size: 1.5rem;
`;

const Part1_Evaluation = () => {
  return (
    <Layout location={typeof window !== 'undefined' ? location : null}>
      <SEO pathname="/fr/part-1-evaluation" />

      <ViewArticle>
        <ViewH1>Ce module t'a plu ? Evalue le !</ViewH1>
        <ViewIcons>
          <ViewSmiley
            href="/fr/part-1-quizz"
            onClick={() => {
              trackEvent(
                'Evaluation Page Part 1',
                'User selected red smiley',
                'yes',
              );
            }}
          >
            <img src="/smileys/smiley-ok.jpg" />
          </ViewSmiley>
          <ViewSmiley
            href="/fr/part-1-quizz"
            onClick={() => {
              trackEvent(
                'Evaluation Page Part 1',
                'User selected yellow smiley',
                'yes',
              );
            }}
          >
            <img src="/smileys/smiley-great.jpg" />
          </ViewSmiley>
          <ViewSmiley
            href="/fr/part-1-quizz"
            onClick={() => {
              trackEvent(
                'Evaluation Page Part 1',
                'User selected green smiley',
                'yes',
              );
            }}
          >
            <img src="/smileys/smiley-awesome.jpg" />
          </ViewSmiley>
        </ViewIcons>
        <Link to="/fr/part-1-videos">
          <ViewButtonPrevious color="warning">
            <i className="fa fa-chevron-left fa" />
          </ViewButtonPrevious>
        </Link>
        <Link to="/fr/part-1-quizz">
          <ViewButtonNext color="warning">
            <i className="fa fa-chevron-right" />
          </ViewButtonNext>
        </Link>
        <ViewModuleName>
          <ViewChoice>
            <ViewImgTop src={Borne1} />
            Gérer les revenus de mon activité
          </ViewChoice>
        </ViewModuleName>
      </ViewArticle>
      <Player sound={Sound} />
      <ShareButtons />
      <Breadcrumbs active={6} />
    </Layout>
  );
};

export default Part1_Evaluation;
